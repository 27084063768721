import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainNavy,
  innerWidth,
  mainViolet,
  screen,
} from "../../components/common/variables";
import Nav from "../../components/nav";
import StyledButton from "../../components/styled-button";
import ProgressBar from "../../components/progress-bar";
import BottomNav from "../../components/bottom-nav";
import { MdArrowForward } from "react-icons/md";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  overflow: auto;
  color: ${mainWhite};
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 140px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 140px 0 0 0;
    }

    .select {
      margin: 0 70px 0 0;
      max-width: 900px;
      width: 100%;
      border-bottom: 2px #a8acb2 solid;
      position: relative;
      cursor: pointer;

      .placeholder {
        color: #a8acb2;
        font-size: 1.76rem;
      }

      .arrow {
        position: absolute;
        right: 0;
        top: calc(50% + 1px);
        transform: translateY(-50%);

        svg {
          color: #a8acb2;
          font-size: 2.6rem;
        }
      }

      .dropdown-list {
        position: absolute;
        left: 0;
        top: 58px;
        background: #fff;
        width: 600px;
        padding: 29px 27px;

        li {
          color: #172637;
          font-size: 1.1rem;
          margin: 0 0 13px 0;

          @media ${screen.withCursor} {
            &:hover {
              color: ${mainViolet};
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }

      &--active {
        border-bottom: 2px #fff solid;

        .placeholder {
          color: #fff;
        }

        .arrow {
          svg {
            color: #fff;
          }
        }
      }
    }

    .btns {
      &__each {
        margin: 0 0 14px 0;

        &--disabled {
          a {
            background: #848b93;
            border: 2px #848b93 solid;
            color: #a5a9ad;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

const DiscoverOne = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [select, setSelect] = useState("I want to check which of my apps are…");

  const isDisabled = () => {
    if (select === "I want to check which of my apps are…") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Layout>
      <SEO title="Discover - 2" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList}>
          <ProgressBar>
            <h2 className="title">DISCOVER</h2>
            <div className="bars">
              <p className="selected">1. Import</p>
              <p className="selected">2. Check</p>
              <p className="selected selected--not-selected">3. Report</p>
            </div>
          </ProgressBar>

          <div className="inner-wrapper">
            <motion.div
              variants={variantsItem}
              className={isDisabled() ? "select" : "select select--active"}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <p className="placeholder">{select}</p>

              <span className="arrow">
                <MdArrowForward />
              </span>

              {isDropdownOpen && (
                <ul className="dropdown-list">
                  <li
                    onClick={() =>
                      setSelect("I want to check which of my apps are…")
                    }
                  >
                    Suitable for modernization​
                  </li>
                  <li
                    onClick={() =>
                      setSelect(
                        "Suitable for Windows Virtual Desktop in their current state"
                      )
                    }
                  >
                    Suitable for Windows Virtual Desktop in their current state
                  </li>

                  <li
                    onClick={() =>
                      setSelect("I want to check which of my apps are…")
                    }
                  >
                    Suitable for Windows Virtual Desktop Multi-Session
                  </li>
                  <li
                    onClick={() =>
                      setSelect(
                        "Could not be discovered and/or are unsuitable​"
                      )
                    }
                  >
                    Could not be discovered and/or are unsuitable​
                  </li>
                </ul>
              )}
            </motion.div>
            <motion.div className="btns" variants={variantsItem}>
              <StyledButton
                to={`/discover/${
                  select ===
                  "Suitable for Windows Virtual Desktop in their current state"
                    ? "3/"
                    : "3-5/"
                }`}
                className={
                  isDisabled()
                    ? "btns__each btns__each--disabled"
                    : "btns__each"
                }
              >
                NEXT
              </StyledButton>
            </motion.div>
          </div>
        </motion.div>

        <BottomNav
          leftLabel={"BACK"}
          leftLink={"/discover/1/"}
          rightLabel={"BACK TO START"}
          rightLink={"/"}
        />
      </Wrapper>
    </Layout>
  );
};

export default DiscoverOne;
